
import $http from "@/plugins/http";
import { required, validEmail, validEmailOrCpf } from "@/utils/validationFunctions";
import vm from "@/viewModels/MainViewModel";
import { defineComponent, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Form from "@/components/Form.vue";
import LoginInput from "@/components/LoginInput.vue";
import { trackers } from "@/hooks/insights";
import router from "@/router";
import ProctoringService from "@/services/ProctoringService";
import FirebaseService from "@/services/FirebaseService";
import { UserCredential } from "firebase/auth";
import UsuarioService from "@/services/UsuarioService";
import SchedulingLocalDTO from "@/dtos/SchedulingLocalDTO";
import { deviceId } from "@/utils/stringFunctions";


interface LoginFormViewState {
    email: string;
    emailOrCpf: string;
    cpf: string;
    password: string;
    loading: boolean;
    error: string;
    verified: boolean;
}

const LoginMainView = defineComponent({
    components: { Form, LoginInput },
    setup() {

        const schedulingLocal = ref<SchedulingLocalDTO | null>(null);

        // Recebe a mensagem do iframe com o schedulingLocal
        window.addEventListener('message', function(event) {
            let messageType: string = "";
            messageType = event?.data?.type ?? '';
            if (messageType == 'schedulingLocal') {
                schedulingLocal.value = event.data.payload as SchedulingLocalDTO;
                vm.schedulingLocal = schedulingLocal.value.identifier.replace("CE.", "");
            }
        });            

        const device = ref<string>(deviceId());
        const apiUrl = ref<string>((process.env.VUE_APP_API_URL)!.toString());

        // Hooks
        const { replace } = useRouter();

        // State
        const state = reactive<LoginFormViewState>({
            email: "",
            emailOrCpf: "",
            cpf: "",
            password: "",
            loading: false,
            error: "",
            verified: false,
        });

        watch(
            () => state.emailOrCpf,
            (newValue, oldValue) => {
                const isCpf = /\d{11}/.test(newValue);
                if (isCpf && newValue.length == 11) {
                    const cpf =
                        newValue.substring(0, 3) +
                        "." +
                        newValue.substring(3, 6) +
                        "." +
                        newValue.substring(6, 9) +
                        "-" +
                        newValue.substring(9, 11);
                    state.emailOrCpf = cpf;
                }
                if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(oldValue)) {
                    const value = newValue.replace(new RegExp("[.-]", "gi"), "");
                    state.emailOrCpf = value;
                }
            }
        );

        const verify = async () => {
            state.error = "";
            try {
                const valido = validEmail(state.emailOrCpf);
                if (valido.length > 0) {
                    state.error = valido;
                    throw valido;
                }
                state.loading = true;

                const [request] = UsuarioService.PostVerifyEmail(state.emailOrCpf);
                request
                    .then((resp) => {
                        if (resp.isPassword) {
                            state.verified = true;
                            state.cpf = resp.cpf;
                        } else {
                            trackers.registerPageView(state.email, `/register-password/${resp.cpf}`)
                            router.push(`/register-password/${resp.cpf}`);
                        }
                    })
                    .catch((err: any) => {
                        trackers.registerError(state.emailOrCpf, err.toString())
                        state.error = err;
                    })
                    .finally(() => state.loading = false);
            } catch (error: any) {
                trackers.registerError(state.emailOrCpf, error.toString())
                state.error = error;
                state.loading = false;
            }
        };

        const loginFireBase = async (email: string, password: string) => {
            let userCredential: UserCredential | null = null;
            try {
                // fazer login no Firebase
                userCredential = await FirebaseService.login(email, password);
                vm.sessionFireBase = {
                    uid: userCredential.user.uid,
                    email: userCredential.user.email as string,
                    password: state.password
                };

            } catch (error) {
                console.error(error);
                trackers.registerError(email, error as string);
                userCredential = await FirebaseService.createUser(email, password);
                vm.sessionFireBase = {
                    uid: userCredential.user.uid,
                    email: userCredential.user.email as string,
                    password: state.password
                };
            } finally { 
                await FirebaseService.watchUser();
            }
        }


        const submit = async (data: { isValid: boolean; errors: string[] }) => {
            state.error = "";
            if (data.isValid) {
                try {
                    state.loading = true;

                    const [request] = UsuarioService.Login({
                        email: state.emailOrCpf,
                        password: state.password,
                    });
                    const user = await request;

                    if (user.federativeUnit != 'CE') {
                        const [requestProctoring] = ProctoringService.LoginProctoring({
                            emailOrCpf: state.emailOrCpf,
                            key: state.password,
                        });
                        const userProctoring = await requestProctoring;
                        vm.userProctoring = userProctoring;
                    }
                    

                    // Verificar se é admin ou auditor
                    if (user.roleDescription != "ROOM_INSPECTOR" && user.roleDescription != "ADMIN" && user.roleDescription != "VIEWER" && user.roleDescription != "COORD" && user.roleDescription != "SUPPORT" && user.roleDescription != "MASTER") {
                        trackers.registerError(user.email, "Seu usuário não tem autorização para acessar esta página");
                        throw "Seu usuário não tem autorização para acessar esta página";
                    }

                    // Setar o usuário
                    $http.defaults.headers["Authorization"] = "Bearer " + user.token;
                    vm.user = user;

                    //login Firebase
                    if (user.roleDescription == "ADMIN" || user.roleDescription == "SUPPORT" || user.roleDescription == "MASTER") {
                        await loginFireBase(state.emailOrCpf, state.password);
                    }

                    if(user.roleDescription == "ROOM_INSPECTOR"){
                        replace("/viewer-checkin");
                        return;
                    }

                    trackers.registerPageView(!user.email ? user.cpf : user.email, "/exames")
                    replace({ name: "exames" });

                } catch (error: any) {
                    trackers.registerError(state.emailOrCpf ? state.emailOrCpf : "", error.toString());
                    state.error = error;
                    state.loading = false;

                }
            } else {
                state.error = data.errors[0];
                trackers.registerError(state.emailOrCpf ? state.emailOrCpf : "", JSON.stringify(state.error));
            }
        };

        return { state, submit, required, validEmailOrCpf, verify, validEmail, vm, apiUrl, device, schedulingLocal };
    },
});

export default LoginMainView;
