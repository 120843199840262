
import { computed, defineComponent, reactive, ref } from "vue";
import { useAlert } from "@/hooks/alert";
import vm from "@/viewModels/MainViewModel";

//  Components
import Header from "@/components/Header.vue";
import BaseAvatar from "@/components/BaseAvatar.vue";

import UserDTO from "@/dtos/UserDTO";
import { useRouter } from "vue-router";
import Dropdown from "@/components/Dropdown.vue";
import ConfigModal from "@/components/ConfigModal.vue";
import Icon from "@/components/Icon.vue";
import { removeAuditorDocExam, watchDocExam } from "@/repositories/FirestoreExams";
import ViewerCheckinView from "../viewer-checkin/ViewerCheckinView.vue";

const AdminMainPage = defineComponent({
    components: { Header, avatar: BaseAvatar, Dropdown, Icon, ConfigModal },
    setup() {
        const user = computed<UserDTO>(() => vm.user!);
        const alert = useAlert();
        const { replace } = useRouter();

        const state = reactive({
            businessName: '',
            showDropDown: false,
            showModalConfigProctoring: false
        });

        const logout = () => {
            alert({
                icon: "logout",
                title: "Logout",
                message: "Tem certeza que deseja sair do sistema?",
                actions: [
                    {
                        title: "Cancelar",
                        primary: true,
                    },
                    {
                        title: "Sair",
                        action: async () => {
                            await removeAuditor();
                            replace({ name: "login" });
                            vm.logout();
                        },
                    },
                ],
            });
        };

        const viewCertificates = () => {
            state.showDropDown = !state.showDropDown;
            replace({ name: 'finished-exam-view' });
        };

        const viewViewerCheckin = () => {
            state.showDropDown = !state.showDropDown;
            replace({ name: 'viewer-checkin' });
        };

        const examsViewer = () => {
            state.showDropDown = !state.showDropDown;
            window.location.replace('/exames');
        };

        const justificativas = () => {
            state.showDropDown = !state.showDropDown;
            replace({ name: 'justificativas' });
        };

        const ChangeExaminer = () => {
            state.showDropDown = !state.showDropDown;
            replace({ name: 'change-examiner' });
        };

        const openUserPage = () => {
            state.showDropDown = !state.showDropDown
            replace({ name: 'create-users' });
        };

        const submit = () => {
            state.showDropDown = true;
            // do more
        };


        const toogleModal = () => {
            state.showModalConfigProctoring = !state.showModalConfigProctoring;
        }

        const removeAuditor = async () => {
            const schedulingId = localStorage.getItem('schedulingId');
            const auditorCpf = localStorage.getItem('auditorCpf');
            if (schedulingId && auditorCpf) {
                const unsubscribeDocExam = await watchDocExam(schedulingId as string);
                if (unsubscribeDocExam) {
                    unsubscribeDocExam();
                    await removeAuditorDocExam(schedulingId, auditorCpf);
                }
            }
        }

        return { justificativas, ChangeExaminer, logout, user, state, vm, submit, openUserPage, toogleModal, removeAuditor, viewCertificates, examsViewer, viewViewerCheckin };
    },
});

export default AdminMainPage;
