
import { computed, defineComponent, onMounted, reactive, PropType } from "vue";
import Icon from "@/components/Icon.vue";
import { format } from "date-fns";
import { maskCpf } from '@/utils/stringFunctions';
import vm from "@/viewModels/MainViewModel";
import { CandidateDTO } from "@/dtos/CandidateDTO";
import { getDocExam } from "@/repositories/FirestoreExams";
import AuditDTO from "@/dtos/AuditDTO";

const SchedulingStatusEnum: { [key: string]: string } = {
    NONE: "Em aberto",
    STARTED: "Em andamento",
    DONE: "Executado",
};

const SchedulingResultStatusEnum: { [key: string]: string } = {
    WAITING_AUDITING: "Aguardando auditoria",
    IN_ANALYSIS: "Em análise",
    WAITING_CONFIRMATION: "Aguardando confirmação do examinador",
    DISQUALIFIED: "Desclassificado",
    RELEASED: "Liberado",
    NONE: "Em execução",
};

const CandidateCard = defineComponent({
    components: {
        Icon
    },
    emits: ['startAudit'],
    props: {
        candidate: {
            type: Object as PropType<CandidateDTO>,
            required: true
        },
        onFocus: {
            type: Boolean,
            default: false
        },
    },
    setup(props) {

        const state = reactive({
            title: '',
            color: 'var(--primary-color)',
            formattedDate: '',
            active: false,
            isViewing: false
        });

        const iconColor = computed(() => {
            let color: { [key: string]: string } = {
                WAITING_AUDITING: "#fcb103",
                IN_ANALYSIS: "#fcb103",
                WAITING_CONFIRMATION: "#fcb103",
                DISQUALIFIED: "#D95454",
                RELEASED: "#2CC89B",
                NONE: "#bc9bcf",
            };
            return color[props.candidate.statusResult];
        });

        const IconExam = computed(() => {
            let icon: { [key: string]: string } = {
                WAITING_AUDITING: "inanalysis",
                IN_ANALYSIS: "inanalysis",
                WAITING_CONFIRMATION: "inanalysis",
                DISQUALIFIED: "reproved",
                RELEASED: "approved",
                NONE: "timer"
            };
            return icon[props.candidate.statusResult];
        });

        // Status que não pode ser avaliados conforme o tipo de usuário Admin ou Viewer
        const UserAdminNotEvaluationStatus = ['WAITING_AUDITING', 'IN_ANALYSIS', 'WAITING_CONFIRMATION'];
        const UserViewerNotEvaluationStatus = ['NONE', 'WAITING_CONFIRMATION', 'DISQUALIFIED', 'RELEASED'];

        const status = computed(() => props.candidate.statusResult);

        const disabledBtn = computed(() => {
            if (vm.user?.roleDescription === 'SUPPORT' || vm.user?.roleDescription === 'MASTER')
                return false;
            if (vm.user?.roleDescription === 'ADMIN')
                return !UserAdminNotEvaluationStatus.includes(status.value);
            if (vm.user?.roleDescription === 'VIEWER' || vm.user?.roleDescription === 'COORD')
                return !UserViewerNotEvaluationStatus.includes(status.value);
            return true;
        });

        const handleGenerateData = async () => {
            state.formattedDate = format(new Date(props.candidate.date), "dd/MM/yyyy");
            if (vm.user?.roleDescription === 'ADMIN' || vm.user?.roleDescription === 'MASTER') {
                const docExam = await getDocExam(props.candidate.schedulingId);
                if (docExam) {
                    state.isViewing = docExam.data().audit != undefined && docExam.data().audit.length > 0;
                }
            }
        };

        onMounted(() => handleGenerateData());

        return {
            state,
            disabledBtn,
            maskCpf,
            SchedulingResultStatusEnum,
            SchedulingStatusEnum,
            status,
            iconColor,
            IconExam,
            vm
        };
    },
});

export default CandidateCard;
