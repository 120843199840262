
import { defineComponent } from 'vue';
import FinishIllustration from '@/components/illustrations/FinishIllustration.vue';
import vm from "@/viewModels/MainViewModel";
import { useRouter } from 'vue-router';

const MenuPage = defineComponent({
    components: { FinishIllustration },

    setup() {
        const { replace } = useRouter();
        var env = process.env.NODE_ENV
        /** Redirect */
        const redirect = () => {
            const url = process.env.VUE_APP_BUBBLE_URL + `/admin?token=` + vm.user?.token;
            window.open(url);
        };

        //TODO: Refatorar permissão pelas rotas
        if (vm.user?.roleDescription == "ROOM_INSPECTOR") {
            replace("/viewer-checkin");
        }

        return { redirect, env };
    }
});

export default MenuPage;
