
import CandidateCard from "@/components/CandidateCard.vue";
import Card from "@/components/Card.vue";
import Loading from "@/components/Loading.vue";
import SearchInput from "@/components/SearchInput.vue";
import HeaderAudit from "@/components/audit/HeaderAudit.vue";
import ExamIllustration from '@/components/illustrations/ExamIllustration.vue';
import { CandidateDTO } from "@/dtos/CandidateDTO";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import TakeUserPictureDTO from '@/dtos/TakeUserPictureDTO';
import TextJustificationDTO from "@/dtos/TextJustificationDTO";
import AuditScreenEnum from "@/enums/AuditScreenEnum";
import { FederativeUnitEnum, toEnum } from "@/enums/FederativeUnitEnum";
import { UserRoleEnum, toEnum as userRoleToEnum } from "@/enums/UserRoleEnum";
import { useAlert } from "@/hooks/alert";
import { trackers } from "@/hooks/insights";
import useSignalSender from "@/hooks/signalSender";
import { HeaderParams } from "@/interfaces/HeaderParams";
import ExamsQuery from '@/queryObjects/ExamsQuery';
import AuditService from "@/services/AuditService";
import CaptureUserPictureService from "@/services/CaptureUserPictureService";
import ExameService from "@/services/ExameService";
import vm from "@/viewModels/MainViewModel";
import MyAuditView from '@/views/admin/meus-exames/MyAuditView.vue';
import CandidatesMonitoringView from '@/views/monitor/CandidateMonitoringView.vue';
import MyAutomaticAudit from "../meus-exames/MyAutomaticAudit.vue";
import MyPhotoAuditView from "../meus-exames/MyPhotoAuditView.vue";
import CalendarInclude from './CalendarInclude.vue';
import CandidateCardSkeleton from "./CandidateCardSkeleton.vue";
import SettingFilters from './SettingsFilters.vue';
import StatusFilter from './StatusFilter.vue';
import { isAllAlertsAudited } from "@/utils/alertsUtils";
import {
    defineComponent,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    reactive,
    ref,
    toRaw,
    watch,
    watchEffect
} from "vue";
import { useRoute, useRouter } from 'vue-router';
import { removeAuditorDocExam, updateDocExam, watchDocExam } from "@/repositories/FirestoreExams";
import AuditDTO from "@/dtos/AuditDTO";
import { millisToMinutesAndSeconds } from "@/utils/dateTimeFunctions";
import Icon from "@/components/Icon.vue";
import { count } from "firebase/firestore";

interface CandidatesState {
    candidates: CandidateDTO[];
    candidateSelected: CandidateDTO | null;
    candidatesFiltered: CandidateDTO[];
    loading: boolean;
    disableNext: boolean;
    qtd: number;
    calendarOpened: boolean;
    auditID: string;
    examCandidate: ExamResponseDTO | null;
    proctoringAudit: ProctoringResponseDTO | null;
    uploadCandidate: boolean;
    isActive: boolean;
    year?: Date,
    formatDateTime?: Date,
    filterActivated: boolean;
    closeOpenCalendar: boolean;
    closeOpenStatusFilter: boolean;
    startHour: number,
    finishHour: number,
    pageCount: number;
    loadingCandidate: boolean;
    justificationText: TextJustificationDTO | null;
    photos: null | TakeUserPictureDTO[];
    unsubscribeDocExamNotification?: () => void;
    auditScreen: AuditScreenEnum;
    isBackPageEnabled: boolean;
    isNextPageEnabled: boolean;
    alertsAuditedCount: number;
    alertsTotalCount: number;
    userRole: UserRoleEnum;
    headerParams: HeaderParams;
}

type PropsRequerid = 'pageSize' | 'page' | 'checkResultStatusPerRole';
type QueryType = Partial<Omit<ExamsQuery, PropsRequerid>> & Pick<ExamsQuery, PropsRequerid>;

const ExamView = defineComponent({
    components: {
        CandidateCard,
        Loading,
        Card,
        ExamIllustration,
        CandidateCardSkeleton,
        MyAuditView,
        SearchInput,
        CalendarInclude,
        SettingFilters,
        StatusFilter,
        MyPhotoAuditView,
        CandidatesMonitoringView,
        HeaderAudit,
        MyAutomaticAudit,
        Icon,
    },
    props: {
        totalCandidates: {
            type: Number,
            default: 0,
        },
    },
    setup() {
        const alert = useAlert();
        const today = new Date();
        const onScroll = ref<HTMLElement | null>(null);
        const loadCandidateList = ref(false);

        const route = useRoute();
        const { replace } = useRouter();

        //TODO: Refatorar permissão pelas rotas
        if (vm.user?.roleDescription == "ROOM_INSPECTOR") {
            replace("/viewer-checkin");
        }

        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        const roleDescription = vm.user?.roleDescription ?? '';
        const state = reactive<CandidatesState>({
            candidates: [],
            candidateSelected: null,
            candidatesFiltered: [],
            loading: false,
            disableNext: false,
            qtd: 0,
            calendarOpened: false,
            auditID: "",
            examCandidate: null,
            proctoringAudit: null,
            uploadCandidate: false,
            isActive: false,
            year: undefined,
            filterActivated: false,
            closeOpenCalendar: false,
            closeOpenStatusFilter: false,
            startHour: 0,
            finishHour: 4,
            pageCount: 0,
            loadingCandidate: false,
            justificationText: null,
            photos: [],
            auditScreen: AuditScreenEnum.NONE,
            isBackPageEnabled: false,
            isNextPageEnabled: false,
            alertsTotalCount: 0,
            alertsAuditedCount: 0,
            userRole: userRoleToEnum(roleDescription),
            headerParams: {
                candidateUf: FederativeUnitEnum.NONE,
                userRole: UserRoleEnum.NONE,
                hasCameraAlerts: false,
                hasEnvironmentAlerts: false,
                hasQuestionsPhotos: false
            }
        });

        const isMasterRole = state.userRole == UserRoleEnum.MASTER;
        const isAdminRole = state.userRole == UserRoleEnum.ADMIN;
        const isExaminerRole =
            state.userRole == UserRoleEnum.VIEWER ||
            state.userRole == UserRoleEnum.COORD;

        const stateQueryParameters = reactive<Partial<ExamsQuery>>({
            status: undefined,
            resultStatus: undefined,
            order: undefined,
            name: undefined,
            cpf: '',
            date: undefined,
            startHour: 0,
            finishHour: 4,
            pageSize: 10,
            page: 1,
            checkResultStatusPerRole: true,
            noDetails: true,
            federativeUnit: undefined,
            schedulingId: undefined
        });


        const getExams = (arg: QueryType): Promise<CandidateDTO[]> => {
            return new Promise((resolve, reject) => {
                arg.checkResultStatusPerRole = true;
                arg.noDetails = true;
                const [request] = ExameService.GetExam({ ...arg });
                request
                    .then((resp) => {
                        resolve(resp.content as CandidateDTO[]);

                        state.qtd = resp.itensCount;
                        state.pageCount = resp.pageCount;
                        state.disableNext = (resp.currentPage == resp.pageCount) ? true : false;
                    })
                    .catch(reject)
                    .finally(() => {
                        state.loading = false;
                    });
            });
        };

        var stateSelectSignal = useSignalSender("StateSelectSignal");
        const loadCandidates = async (data: { resetData: boolean }) => {
            // Se já estiver carregando ou não tiver mais dados para carregar, não faz nada
            if (state.loading || state.disableNext)
                return;

            if (isSelectedUfEnforced()) {
                stateSelectSignal.send();
                clearCandidates();
                return;
            }

            state.loading = true;

            //Define os parametros
            const queryParams = { ...toRaw(stateQueryParameters) };
            queryParams.startHour = undefined;
            queryParams.finishHour = undefined;
            if (route.params.schedulingId)
                queryParams.schedulingId = route.params.schedulingId.toString();

            queryParams.cpf = isValidString(stateQueryParameters.cpf).replace(new RegExp(/\D/g), '');

            ///Ordena os exames de acordo com o perfil do usuário
            //Usuários ADMIN e VIEWER terão a visualização dos exames mais antigos primeiro: (pois precisam auditá-los com mais urgência)
            //Usuários COORD e SUPPORT terão a visualização dos exames mais recentes primeiro
            const orderAsc = vm.user!.roleDescription == "ADMIN" || vm.user!.roleDescription == "VIEWER";
            queryParams.order = orderAsc ? 2 : 1;

            try {
                //Busca os candidatos
                //Se o atributo `data.resetData` for true deverá recarregar a lista de candidatos do zero
                //Se não, adiciona os novos candidatos ao array de candidatos
                if (data.resetData) {
                    state.candidates = [];
                    state.qtd = 0;
                    queryParams.date = undefined;
                    state.candidates = await getExams(queryParams as QueryType);

                    if (queryParams.schedulingId && state.candidates.length > 0) {
                        state.candidateSelected = state.candidates[0];
                        startAudit(state.candidateSelected);
                    }
                } else {
                    queryParams.date = stateQueryParameters.date;
                    const exams = [...await getExams(queryParams as QueryType)];
                    state.candidates = [...state.candidates, ...exams];
                }
            } catch (e: any) {

                //Registra o erro no datadog
                trackers.registerError(
                    vm.user!.id,
                    "Não foi possível carregar os candidatos, verifique com seu administrador. exception: " + e.toString()
                );

                //Notifica exceção ao usuário
                alert({
                    title: 'Opps...',
                    message: 'Não foi possível carregar os candidatos, verifique com seu administrador.'
                });
                throw new Error('Não foi possível carregar os candidatos, verifique com seu administrador.');
            } finally {
                //Finaliza o carregamento da página
                state.loading = false;
                if (data.resetData)
                    state.uploadCandidate = false;
            }
        };

        const clearCandidates = () => {
            state.candidates = [];
            state.qtd = 0;
        }

        const getProctoringById = async (proctoringId: string): Promise<ProctoringResponseDTO> => {
            try {
                const [request] = ExameService.GetAuditoriaByProctoringId(proctoringId);
                return await request;
            } catch {
                throw 'Não foi possível carregar o proctoring, contate o suporte.';
            }
        }

        const GetExameById = async (examId: string) => {
            try {
                const [request] = ExameService.GetExame(examId);
                return await request;
            } catch {
                throw 'Não foi possível carregar a auditoria, contate o suporte.';
            }
        }

        const getPhotoAudit = async (examID: string): Promise<TakeUserPictureDTO[]> => {
            try {
                const [request] = CaptureUserPictureService.GetUserAudit(examID);
                return await request;
            } catch {
                throw 'Não foi possível carregar as fotos, contate o suporte.';
            }
        }

        const loadJustifications = async () => {
            const [request] = AuditService.GetAllTextJustification();
            state.justificationText = await request;
        }

        const updateStatusAudit = async (schedulingId: string) => {
            const audit: AuditDTO = {
                schedulingId: schedulingId,
                statusAudit: 'viewing',
                auditorCpf: vm.user?.cpf,
                auditorName: vm.user?.name
            }
            await updateDocExam(audit);
        }

        const loadAudit = async (candidate: CandidateDTO) => {
            if (state.loadingCandidate)
                return;

            if (isAdminRole || isMasterRole) {
                await updateStatusAudit(candidate.schedulingId);
                state.unsubscribeDocExamNotification = await watchDocExam(candidate.schedulingId);
            }

            state.loadingCandidate = true;

            try {
                const images = ref<TakeUserPictureDTO[]>([]);


                state.photos = [];
                state.examCandidate = null;
                state.proctoringAudit = null;
                state.isNextPageEnabled = false;
                state.isBackPageEnabled = false;
                state.alertsAuditedCount = 0;
                state.alertsTotalCount = 0;

                //Carrega exame de auditoria
                state.examCandidate = await GetExameById(candidate.id);
                const isAutomaticAudit = state.examCandidate.federativeUnit == "SP";

                // Seleciona a pagina que será exibida
                if (state.examCandidate.status == "STARTED") {
                    isAutomaticAudit ? changeToScreen(AuditScreenEnum.AUTOMATIC) : changeToScreen(AuditScreenEnum.STREAM);
                    return;
                }

                //Carrega alertas de video/ambiente
                if (state.examCandidate.federativeUnit != "CE")
                    state.proctoringAudit = await getProctoringById(state.examCandidate.proctoringId)

                //Para o admin e master não carregar as fotos
                if (isMasterRole || isAdminRole) {
                    changeToScreen(AuditScreenEnum.CAMERA);
                    return;
                }

                images.value = await getPhotoAudit(candidate.id);

                //Se não tiver fotos para auditar
                if (images.value.length == 0) {
                    //Redireciona para a tela de questões quando não tem fotos
                    if (state.examCandidate.federativeUnit != "CE") {
                        changeToScreen(AuditScreenEnum.CAMERA);
                    }
                    else {
                        changeToScreen(AuditScreenEnum.QUESTIONS);
                    }
                }

                //Adiciona o tempo de cada foto
                let photoIndex = 1;
                images.value.map(photo => {
                    const diffMillis = new Date(photo.time).getTime() - new Date(state.candidateSelected!.startDate).getTime();
                    photo.time = millisToMinutesAndSeconds(diffMillis);
                    photo.index = photoIndex++;
                    state.photos?.push(photo)
                });

                // Auditoria de fotos por questão
                changeToScreen(AuditScreenEnum.QUESTIONS);

            } catch (error) {

                changeToScreen(AuditScreenEnum.NONE);
                alert({
                    message: error as string
                });

            } finally {
                state.loadingCandidate = false;
            }
        }

        const releaseViewCandidate = async (candidate: CandidateDTO | null) => {
            if ((isAdminRole || isMasterRole)
                && state.candidateSelected != null
                && state.candidateSelected.schedulingId != candidate?.schedulingId
            ) {
                removeAuditorDocExam(state.candidateSelected.schedulingId, vm.user?.cpf as string);
            }
        }

        const startAudit = async (candidate: CandidateDTO) => {
            changeToScreen(AuditScreenEnum.NONE);

            if (state.loadingCandidate)
                return;
            try {
                await releaseViewCandidate(candidate);
                state.candidateSelected = candidate;
                state.auditID = candidate.id;

                await loadAudit(candidate);

            } catch (error: any) {
                alert({
                    title: "Erro",
                    message: error as string
                });
            }
        };



        const searchExam = async (value: string | any | null) => {
            if (state.loading) {
                return;
            }
            changeToScreen(AuditScreenEnum.NONE);

            if (isSelectedUfEnforced()) {
                stateSelectSignal.send();
                clearCandidates();
                return;
            }

            state.loading = true;
            clearCandidates();
            if (value !== null && typeof value === "object") {
                stateQueryParameters.startHour = value['startHour'];
                stateQueryParameters.finishHour = value['finishHour'];
            }

            stateQueryParameters.page = stateQueryParameters.resultStatus
                || stateQueryParameters.date
                || stateQueryParameters.cpf ? 1 : stateQueryParameters.page;

            const queryParams = { ...toRaw(stateQueryParameters) };
            queryParams.date = stateQueryParameters.date;
            queryParams.cpf = isValidString(stateQueryParameters.cpf).replace(new RegExp(/\D/g), '');

            try {
                state.candidates = await getExams(queryParams as QueryType);
            } catch (error) {
                trackers.registerError('Erro ao buscar candidatos', error!.toString());
                clearCandidates();
            } finally {
                state.loading = false;
                state.uploadCandidate = false;
            }
        };

        const statusConfirmAudit = async (proctoringId: string) => {
            state.loadingCandidate = true;
            const [request] = ExameService.GetAuditoriaByProctoringId(proctoringId);
            request
                .then((resp) => {
                    const alertsAudited = isAllAlertsAudited(resp.alerts);
                    if (!alertsAudited) {
                        alert({
                            title: "Atenção!",
                            message: "Existem alertas não auditados. Não é possível finalizar auditoria",
                        });

                        // Verifica se existe algum alerta em revisão para redirecionar para pagina especifica
                        resp.alerts.map((alert) => {
                            if (alert.alertStatus == 'REVIEWING') {
                                alert.type == "enviroment" ? changeToScreen(AuditScreenEnum.ENVIRONMENT) : changeToScreen(AuditScreenEnum.CAMERA);
                            }
                        });

                        state.loadingCandidate = false;
                        return;
                    }
                    const [request] = ExameService.UpdateStatus(state.candidateSelected!.id, 'WAITING_CONFIRMATION');
                    request.then(() => {
                        state.loadingCandidate = false;
                        state.candidateSelected!.statusResult = "WAITING_CONFIRMATION"
                        startAudit(state.candidateSelected!);
                    })
                        .catch((error) => {
                            state.loadingCandidate = false;
                            trackers.registerError(`AuditId: ${state.candidateSelected!.id}`, `Erro: ${error.toString()}`);
                            alert({
                                title: "Error",
                                message: error
                            });
                        });
                })
                .catch((error) => {
                    trackers.registerError(`AuditId: ${state.candidateSelected!.id}`, `Erro: ${error.toString()}`);
                    alert({ message: error });
                });
        };

        const statusConfirmQualifyAudit = async (proctoringId: string) => {
            state.loadingCandidate = true;
            const [request] = ExameService.GetAuditoriaByProctoringId(proctoringId);
            request
                .then((resp) => {
                    const alertsAudited = isAllAlertsAudited(resp.alerts);
                    if (!alertsAudited) {
                        alert({
                            title: "Atenção!",
                            message: "Existem alertas não auditados. Não é possível finalizar auditoria",
                        });

                        state.loadingCandidate = false;
                        return;
                    }
                    const [request] = ExameService.AuditThenRelease(state.candidateSelected!.id as string, {
                        released: true
                    });
                    request
                        .then(() => {
                            state.candidateSelected!.statusResult = "RELEASED"
                        })
                        .catch((error) => {
                            alert({
                                title: "Error",
                                message: error
                            })
                        })
                        .finally(() => {
                            state.loadingCandidate = false;
                            startAudit(state.candidateSelected!);
                        });
                })
                .catch((error) => {
                    trackers.registerError(`AuditId: ${state.candidateSelected!.id}`, `Erro: ${error.toString()}`);
                    alert({ message: error });
                });
        };

        const confirmAudit = () => {
            let message = 'Tem certeza que deseja liberar o candidato?';
            alert({
                title: 'Confirmar',
                message: message,
                actions: [
                    {
                        title: 'Cancelar',
                    },
                    {
                        title: 'Confirmar',
                        action: statusConfirmScheduling
                    }
                ]
            });
        };


        const statusDenyQualifyAudit = (justificativa: string) => {
            state.loadingCandidate = true;
            const [request] = ExameService.AuditThenRelease(state.candidateSelected!.id, {
                released: false,
                justification: justificativa
            });

            request
                .then(() => {
                    state.candidateSelected!.statusResult = "DISQUALIFIED"
                })
                .catch((error) => {
                    alert({ message: error });
                })
                .finally(() => {
                    state.loadingCandidate = false;
                    startAudit(state.candidateSelected!);
                });
        };

        const statusDeniedScheduling = (justificativa: string) => {
            state.loadingCandidate = true;
            const [request] = ExameService.AuditAll(state.candidateSelected!.id, {
                released: false,
                justification: justificativa
            });

            request
                .then(() => {
                    state.candidateSelected!.statusResult = "DISQUALIFIED";
                })
                .catch((error) => {
                    alert({ message: error });
                })
                .finally(() => {
                    state.loadingCandidate = false;
                    startAudit(state.candidateSelected!);
                });
        };

        const statusConfirmScheduling = () => {
            state.loadingCandidate = true;
            const [request] = ExameService.AuditAll(state.candidateSelected!.id as string, {
                released: true
            });
            request
                .then(() => {
                    state.candidateSelected!.statusResult = "RELEASED";
                })
                .catch((error) => {
                    alert({
                        title: "Error",
                        message: error
                    })
                })
                .finally(() => {
                    state.loadingCandidate = false;
                    startAudit(state.candidateSelected!);
                });
        };

        function openCloseFilter() {
            state.closeOpenStatusFilter = !state.closeOpenStatusFilter;
        }

        function ufChanged(uf: string | undefined, name: string | undefined) {
            stateQueryParameters.federativeUnit = uf;
            if (!state.closeOpenCalendar)
                searchExam(null);
        }

        const unsubscribeDocExam = () => {
            if (state.unsubscribeDocExamNotification) {
                state.unsubscribeDocExamNotification();
            }
        }

        // outra alternativa seria colocar uma paginação de forma que podesse carregar uma lista limitada e passar para o lado a paginação.
        watch(() => loadCandidateList.value, (newValue) => {
            if (state.loading) {
                return;
            }
            stateQueryParameters.page = (stateQueryParameters.page ?? 0) + 1;
            loadCandidates({ resetData: false });
        });

        watch(() => state.candidateSelected, () => {
            if (state.candidateSelected) {
                localStorage.setItem('schedulingId', state.candidateSelected.schedulingId)
            }
        });

        watch(() => stateQueryParameters.cpf, () => {
            if (stateQueryParameters.cpf == '') {
                const schedulingId = localStorage.getItem('schedulingId');
                const auditorCpf = localStorage.getItem('auditorCpf');
                if (schedulingId && auditorCpf) {
                    unsubscribeDocExam();
                    removeAuditorDocExam(schedulingId, auditorCpf);
                }
            }
        })

        watchEffect(() => {
            state.headerParams.hasQuestionsPhotos = (state.photos?.length ?? 0) > 0;
            state.headerParams.hasCameraAlerts = (state.proctoringAudit?.alerts?.length ?? 0) > 0;
            state.headerParams.hasEnvironmentAlerts = (state.proctoringAudit?.imageAlerts?.length ?? 0) > 0;
            state.headerParams.candidateUf = toEnum(state.examCandidate?.federativeUnit ?? '');
            state.headerParams.userRole = state.userRole;
        });


        const handleScroll = () => {
            scrollAction();
            onScroll.value?.addEventListener('scroll', scrollAction);
        };

        const scrollEventSender = useSignalSender("ExamListScrolledEvent");
        function scrollAction() {
            const scrollTop = Number(onScroll.value?.scrollTop);
            const scrollHeight = Number(onScroll.value?.scrollHeight);
            const clientHeight = Number(onScroll.value?.clientHeight);
            let value = (100 * scrollTop / (scrollHeight - clientHeight));
            loadCandidateList.value = value >= 100;

            scrollEventSender.send();
        }

        function isValidString(value: string | undefined | null): string {
            if (value !== null && value !== undefined) {
                return value.trim();
            }
            return '';
        }

        function cancelCalendar() {
            stateQueryParameters.date = undefined;
            state.closeOpenCalendar = !state.closeOpenCalendar;
            state.disableNext = false;
            state.loading = false;
            loadCandidates({ resetData: true })
        }


        const changeToScreen = (screen: AuditScreenEnum) => {

            state.auditScreen = screen;

            //AUTOMATIC / STREAM
            state.isBackPageEnabled = false;
            state.isNextPageEnabled = false;

            const backToQuestions = !isAdminRole && !isMasterRole && (state.photos!.length > 0 || state.candidateSelected?.federativeUnit != 'CE');
            const nextToEnviroment = state.proctoringAudit == null ? false :
                state.proctoringAudit?.imageAlerts!.length > 0 ? true : false;
            const nextToCamera = state.candidateSelected?.federativeUnit != "CE";

            if (screen == AuditScreenEnum.QUESTIONS) {
                state.isBackPageEnabled = false;
                state.isNextPageEnabled = nextToCamera;
                return;
            }

            if (screen == AuditScreenEnum.CAMERA) {
                state.isBackPageEnabled = backToQuestions;
                state.isNextPageEnabled = nextToEnviroment;
                return;
            }

            if (screen == AuditScreenEnum.ENVIRONMENT) {
                state.isBackPageEnabled = true;
                state.isNextPageEnabled = false;
                return;
            }

        };

        const updateAutomaticaAlerts = (allAlertsCount: any) => {
            state.alertsAuditedCount = allAlertsCount;
            state.alertsTotalCount = allAlertsCount;
        }


        const onAlertsTotalUpdated = (value: number) => {
            state.alertsTotalCount = value;
        };
        const onAlertsAuditedUpdated = (value: number) => {
            state.alertsAuditedCount = value;
        };
        const handleUnload = (event: Event) => {
            if ((isAdminRole || isMasterRole) && state.candidateSelected != null) {
                removeAuditorDocExam(state.candidateSelected.schedulingId, vm.user?.cpf as string);
            }
        }


        onBeforeMount(() => { onScroll.value?.removeEventListener('scroll', scrollAction); });

        const onCalendarClicked = () => {
            state.closeOpenCalendar = !state.closeOpenCalendar;
        };

        //Comentado para o momento pois não foi definido a regra de quando o filtro de UF deve ser obrigatório
        const isSelectedUfEnforced = () => false;
        // (isAdminRole || isMasterRole) && 
        // !stateQueryParameters.federativeUnit &&
        // !stateQueryParameters.cpf 

        onMounted(() => {
            loadJustifications();
            loadCandidates({ resetData: true });
            handleScroll();
            window.addEventListener('beforeunload', handleUnload);
            if (isAdminRole || isMasterRole)
                localStorage.setItem('auditorCpf', vm.user?.cpf as string);
        });

        onBeforeUnmount(() => {
            //remover auditor da lista de visualização de documento
            if ((isAdminRole || isMasterRole) && state.candidateSelected != null) {
                removeAuditorDocExam(state.candidateSelected.schedulingId, vm.user?.cpf as string);
            }
        });


        return {
            state,
            stateQueryParameters,
            startAudit,
            onScroll,
            searchExam,
            cancelCalendar,
            roleDescription,
            openCloseFilter,
            statusDeniedScheduling,
            statusConfirmScheduling,
            statusConfirmAudit,
            statusDenyQualifyAudit,
            statusConfirmQualifyAudit,
            ufChanged,
            changeToScreen,
            onAlertsAuditedUpdated,
            onAlertsTotalUpdated,
            confirmAudit,
            isExaminerRole,
            onCalendarClicked,
            isSelectedUfEnforced,
            AuditScreenEnum,
            updateAutomaticaAlerts
        };
    },
});

export default ExamView;
