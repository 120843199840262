
import { computed, defineComponent, inject, onMounted, PropType, Ref } from 'vue';
import { FormField } from './Form.vue';
import Loading from './Loading.vue';
import { maska } from 'maska';
const LoginInput = defineComponent({

    /*
    USER = 0,
        VSOFT
        ADMIN = 1,
        SUPPORT = 2,
        DETRAN
        VIEWER = 4,
        COORD = 5
        */
    directives: { maska },
    components: { Loading },
    props: {
        modelValue: {
            type: String,
            required: true
        },
        rules: {
            type: Array as PropType<((value: string) => string)[]>,
        },
        hasButton: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        mask: {
            type: [String, Object]
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        displayError: {
            type: Boolean,
            default: true
        },
        buttonInError: {
            type: Boolean,
            default: false
        },
        inputClass: {
            type: String,
        }
    },
    setup(props, context) {
        const addField = inject<(data: FormField) => null>("addField");
        const fields = inject<Ref<FormField[]>>('fields');
        const onInput = inject<() => void>('onInput');


        const error = computed(() => fields?.value.find(el => el.name == props.name)?.error || "");

        const inputValue = computed({
            get() {
                return props.modelValue;
            },
            set(value: any) {
                context.emit("update:modelValue", value);
                onInput && onInput();
            }
        });


        onMounted(() => {
            if(fields && props.rules && addField) {
                addField({ name: props.name, error: "", validation: props.rules });
            }
        });

        return { inputValue, error };
    }
});

export default LoginInput;
