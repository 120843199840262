import { Plugin, createApp } from 'vue';
import OpenViewerRoomModal from '@/components/OpenViewerRoomModal.vue';

interface Option {
    title: string;
    action?: (args?: any) => void;
    primary?: boolean;
}

let useOpenViewerRoomModal: () => ((options: {
    title?: string;
    message: string;
    actions?: Option[];
    icon?: string;
    detail?: string;
    color?: string;
}) => void
);

const openViewerRoomModal: Plugin = {
    install: () => {
        const alertRoot = createApp(OpenViewerRoomModal).mount(document.body.appendChild(document.createElement('div')));
        useOpenViewerRoomModal = () => (alertRoot as any).displayAlert;
    }
};

export { useOpenViewerRoomModal };

export default openViewerRoomModal;