import { formatISO } from "date-fns";

export const millisToMinutesAndSeconds = (millis: number) : string => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.padStart(2, '0')}`;
};

export const nowISO = () => {
    return formatISO(new Date());
};