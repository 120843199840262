import { CreateExamDTO } from "@/dtos/CreateExamDTO";
import { ExamTypeDTO } from "@/dtos/ExamTypeDTO";
import { UnlockExamDTO } from "@/dtos/UnlockExamDTO";
import { GetGestor, PostGestor } from "./BaseService";

export default {
    createExamGestor: async (createExam: CreateExamDTO) => {
        const exam = await PostGestor<CreateExamDTO, any>('app/exam/exams/create', { ...createExam })[0];
        return exam;
    },

    unlockExam: async (schedulingId: string) => {
        const exam = await PostGestor<UnlockExamDTO, any>('app/exam/exams/easy-proctor-unlock-exam', { schedulingId: schedulingId, reason: "paid" })[0];
        return exam;
    },

    getAllExamTypes: () => {
        const exam = GetGestor<ExamTypeDTO[]>(`Exam/exams/get-all-types`);
        return exam;
    },
};

