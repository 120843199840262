
import Button from "@/components/Button.vue";
import Loading from "@/components/Loading.vue";
import Switch from "@/components/ToogleSwitch.vue";
import ViewerCheckInExamDTO from "@/dtos/ViewerCheckInExamDTO";
import { maskCpf } from '@/utils/stringFunctions';
import { PropType, defineComponent } from "vue";

const CheckInExamCard = defineComponent({
    components: { Switch, Button, Loading },
    props: {
        exam: {
            type: Object as PropType<ViewerCheckInExamDTO>,
            required: true
        }
    },
    setup(props, context) {
        
        return { maskCpf };
    },

});

export default CheckInExamCard;
