import { reactive, watch } from "vue";
import UserDTO from '@/dtos/UserDTO';
import $http from '@/plugins/http';
import ImageAlertListDTO from "@/dtos/ImageAlertListDTO";
import SessionDTO from "@/dtos/SessionDTO";
import { renderThemeByName } from "@/utils/themes";

interface MainViewModelState {
    pageTitle: string;
    user: UserDTO | null;
    userProctoring: UserDTO | null;
    federativeUnits: FederativeUnit[];
    federativeUnitSelected: string;
    imageAlerts: ImageAlertListDTO[];
    sessionFireBase: SessionDTO | null;
    schedulingLocal: string;
    appVersion: string;
    hiddenMenu: boolean;
    theme: string;
}
export interface FederativeUnit {
    uf: string,
    name: string,
    isActive: boolean,
    theme?: string
}
class MainViewModel {

    private state = reactive<MainViewModelState>({
        user: null,
        pageTitle: '',
        schedulingLocal: '',
        userProctoring: null,
        federativeUnits: [
            {
                "name": "São Paulo", "uf": "SP",
                isActive: false
            },
            {
                "name": "Demo", "uf": "DEMO",
                isActive: true
            },
            {
                "name": "Ceará", "uf": "CE",
                isActive: true,
                theme: 'valid-theme',
            },
            {
                "name": "Distrito Federal", "uf": "DF",
                isActive: true,
                theme: 'valid-df-theme' ,
            },
            {
                "name": "Acre", "uf": "AC",
                isActive: false
            },
            {
                "name": "Alagoas", "uf": "AL",
                isActive: false
            },
            {
                "name": "Amapá", "uf": "AP",
                isActive: false
            },
            {
                "name": "Amazonas", "uf": "AM",
                isActive: false
            },
            {
                "name": "Bahia", "uf": "BA",
                isActive: false
            },
            {
                "name": "Espírito Santo", "uf": "ES",
                isActive: false
            },
            {
                "name": "Goiás", "uf": "GO",
                isActive: false
            },
            {
                "name": "Maranhão", "uf": "MA",
                isActive: true
            },
            {
                "name": "Mato Grosso", "uf": "MT",
                isActive: false
            },
            {
                "name": "Mato Grosso do Sul", "uf": "MS",
                isActive: false
            },
            {
                "name": "Minas Gerais", "uf": "MG",
                isActive: false
            },
            {
                "name": "Pará", "uf": "PA",
                isActive: false
            },
            {
                "name": "Paraíba", "uf": "PB",
                isActive: true
            },
            {
                "name": "Paraná", "uf": "PR",
                isActive: false
            },
            {
                "name": "Pernambuco", "uf": "PE",
                isActive: true
            },
            {
                "name": "Piauí", "uf": "PI",
                isActive: false
            },
            {
                "name": "Rio de Janeiro", "uf": "RJ",
                isActive: false
            },
            {
                "name": "Rio Grande do Norte", "uf": "RN",
                isActive: false
            },
            {
                "name": "Rio Grande do Sul", "uf": "RS",
                isActive: false
            },
            {
                "name": "Rondônia", "uf": "RO",
                isActive: false
            },
            {
                "name": "Roraima", "uf": "RR",
                isActive: false
            },
            {
                "name": "Santa Catarina", "uf": "SC",
                isActive: false
            },
            {
                "name": "São Paulo", "uf": "SP",
                isActive: false
            },
            {
                "name": "Sergipe", "uf": "SE",
                isActive: false
            },
            {
                "name": "Tocantins", "uf": "TO",
                isActive: false
            }
        ],
        federativeUnitSelected: "",
        appVersion: "",
        imageAlerts: [],
        hiddenMenu: false,
        sessionFireBase: null,
        theme: "default-theme"
    });

    constructor() {
        // Carregar formulário armazenado
        const userString = localStorage.getItem('EP_ADMIN_USER');
        const userBackup = userString ? JSON.parse(userString) as UserDTO : null;
        const sessionFireBase = JSON.parse(localStorage.getItem('sessionFireBase')!) as SessionDTO;

        if (userBackup) {
            this.user = userBackup;
            $http.defaults.headers['Authorization'] = "Bearer " + userBackup.token;
        }

        watch(() => this.state.user, () => {
            localStorage.setItem('EP_ADMIN_USER', JSON.stringify(this.state.user));
        });

        // Proctoring
        const userStringProctoring = localStorage.getItem('EP_ADMIN_USER_PROCTORING');
        const userBackupProctoring = userStringProctoring ? JSON.parse(userStringProctoring) as UserDTO : null;

        if (userBackupProctoring) {
            this.userProctoring = userBackupProctoring;
        }

        watch(() => this.state.userProctoring, () => {
            localStorage.setItem('EP_ADMIN_USER_PROCTORING', JSON.stringify(this.state.userProctoring));
        });

        if (sessionFireBase) {
            this.sessionFireBase = sessionFireBase;
        }

        watch(() => this.state.sessionFireBase, () => {
            localStorage.setItem('sessionFireBase', JSON.stringify(this.state.sessionFireBase));
        });

        watch(() => this.federativeUnitSelected, () => {
            if (this.federativeUnitSelected != null && this.federativeUnitSelected != undefined && this.federativeUnitSelected != "") {
               
                const federativeUnit = this.state.federativeUnits.find(x => x.uf == this.federativeUnitSelected);
                this.state.theme = federativeUnit?.theme ?? 'default-theme';
            }
        });

        watch(()=> this.state.theme, () => { 
            if (this.state.theme != null && this.state.theme != undefined && this.state.theme != "")
            {
                localStorage.setItem('theme', this.state.theme);
                renderThemeByName(this.state.theme);
            }
        });
    }
    //uf
    public set federativeUnits(value: FederativeUnit[]) {
        this.state.federativeUnits = value;
    }
    public get federativeUnits() {
        return this.state.federativeUnits;
    }
    public get federativeUnitSelected() {
        return this.state.federativeUnitSelected;
    }
    public set federativeUnitSelected(data: string) {
        this.state.federativeUnitSelected = data;
    }

    // Versão
    public get appVersion() {
        return this.state.appVersion;
    }
    public set appVersion(value: string) {
        this.state.appVersion = value;
    }

    // pageTitle
    public get pageTitle() {
        return this.state.pageTitle;
    }
    public set pageTitle(data: string) {
        this.state.pageTitle = data;
    }

    // Hidden Menu
    public get hiddenMenu() {
        return this.state.hiddenMenu;
    }
    public set hiddenMenu(data: boolean) {
        this.state.hiddenMenu = data;
    }


    //schedulingLocal - fluxo CE
    public get schedulingLocal() {

        if (this.state.schedulingLocal == null || this.state.schedulingLocal == undefined || this.state.schedulingLocal == 'undefined' || this.state.schedulingLocal == '') {
            this.state.schedulingLocal = localStorage.getItem('EP_SCHEDULING_LOCAL') ?? '';
        }

        return this.state.schedulingLocal;
    }
    public set schedulingLocal(value: string) {
        this.state.schedulingLocal = value;
        localStorage.setItem('EP_SCHEDULING_LOCAL', value);
    }

    // Usuário
    public get user() {
        return this.state.user;
    }
    public set user(value: UserDTO | null) {
        this.state.user = value;
    }

    public ufName(uf: string): string {
        return this.state.federativeUnits.filter(u => u.uf == uf.toUpperCase())[0]?.name;
    }

    public get imageAlerts() {
        return this.state.imageAlerts;
    }
    public set imageAlerts(value: ImageAlertListDTO[]) {
        this.state.imageAlerts = value;
    }

    // theme
    public get theme() {
        return this.state.theme;
    }
    public set theme(value: string) {
        this.state.theme = value;
    }    

    // Usuário Proctoring
    public get userProctoring() {
        return this.state.userProctoring;
    }
    public set userProctoring(value: UserDTO | null) {
        this.state.userProctoring = value;
    }

    public get sessionFireBase() {
        return this.state.sessionFireBase;
    }

    public set sessionFireBase(value: SessionDTO | null) {
        this.state.sessionFireBase = value;
    }

    public logout() {
        this.state.user = null;
        this.state.userProctoring = null;
        this.sessionFireBase = null;
        localStorage.removeItem('auditorCpf');
        localStorage.removeItem('schedulingId');
        window.location.reload();
    }
}

export default new MainViewModel();