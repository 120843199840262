import { vModelDynamic as _vModelDynamic, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Transition as _Transition, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/icons/error.svg'
import _imports_1 from '@/assets/icons/icon_check.svg'


const _hoisted_1 = { class: "w-full relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-red-500 text-xs pt-1"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_0
}
const _hoisted_4 = {
  key: 2,
  src: _imports_1
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")
  const _directive_maska = _resolveDirective("maska")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode("input", {
      placeholder: _ctx.$attrs['placeholder'],
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputValue = $event)),
      disabled: _ctx.disabled,
      type: _ctx.$attrs['type'],
      autocomplete: "off",
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus', $event))),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('blur', $event))),
      name: _ctx.name,
      class: ["w-full outline-none border px-3\n                        flex items-center\n                    border-gray-300 focus:border-primary\n                      rounded-lg h-14 transition-colors duration-300", [{'pr-12': _ctx.hasButton}, { 'border-red-500': _ctx.error.length > 0 }, _ctx.inputClass]]
    }, null, 42, ["placeholder", "disabled", "type", "name"]), [
      [_vModelDynamic, _ctx.inputValue],
      [_directive_maska, _ctx.mask]
    ]),
    (_ctx.displayError)
      ? (_openBlock(), _createBlock("p", _hoisted_2, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    (_ctx.hasButton)
      ? (_openBlock(), _createBlock("button", {
          key: 1,
          class: [{'bg-primary': !_ctx.buttonInError}, "absolute w-8 h-8 bg-red-500 rounded-md right-0 mr-3 flex items-center justify-center transition-all duration-300"],
          style: {"top":"0.75rem"}
        }, [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
                : (_ctx.buttonInError)
                  ? (_openBlock(), _createBlock("img", _hoisted_3))
                  : (_openBlock(), _createBlock("img", _hoisted_4))
            ]),
            _: 1
          })
        ], 2))
      : _createCommentVNode("", true)
  ]))
}