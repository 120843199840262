
import { nowISO } from "@/utils/dateTimeFunctions";
import { gerarCpfAleatorio, gerarNomeAleatorio } from "@/utils/stringFunctions";
import { required, validCpf, validEmailOrCpf } from "@/utils/validationFunctions";
import { defineComponent, onMounted, reactive, watch } from "vue";
import { useRouter } from "vue-router";

import Form from "@/components/Form.vue";
import LoginInput from "@/components/LoginInput.vue";
import SelectStates from "@/components/SelectStates.vue";
import { CreateExamDTO } from "@/dtos/CreateExamDTO";
import { ExamTypeDTO } from "@/dtos/ExamTypeDTO";
import GestorService from "@/services/GestorService";
import vm from "@/viewModels/MainViewModel";
import { uuid } from 'vue-uuid';
import ExameService from "@/services/ExameService";

interface LoginFormViewState {
    cpf: string;
    email: string;
    name: string;
    renach: string;
    companyIdentityDocument: string;
    password: string;
    loading: boolean;
    error: any;
    selectUfModal: boolean;
    gerarProva: boolean;
    selectedUf: string;
    errorUf: boolean;
    examTypes: ExamTypeDTO[];
    selectedExamType: string;
    schedulingId: string;
}

const DemoRegisterView = defineComponent({
    components: { Form, LoginInput, SelectStates },
    setup() {

        // Hooks
        const { replace } = useRouter();

        // Demo cannot be used in production
        if (process.env.NODE_ENV === 'production')
            replace("/login");


        // State
        const state = reactive<LoginFormViewState>({
            cpf: gerarCpfAleatorio(),
            email: "",
            name: gerarNomeAleatorio(),
            renach: "",
            companyIdentityDocument: "",
            password: "",
            loading: false,
            error: "",
            selectUfModal: false,
            gerarProva: false,
            selectedUf: "",
            errorUf: false,
            examTypes: [],
            selectedExamType: "5",
            schedulingId: "",
        });

        onMounted(async () => {
            vm.federativeUnitSelected = "";
            state.examTypes = await GestorService.getAllExamTypes()[0];
        });

        watch(
            () => state.cpf,
            (newValue, oldValue) => {
                const isCpf = /\d{11}/.test(newValue);
                if (isCpf && newValue.length == 11) {
                    const cpf =
                        newValue.substring(0, 3) +
                        "." +
                        newValue.substring(3, 6) +
                        "." +
                        newValue.substring(6, 9) +
                        "-" +
                        newValue.substring(9, 11);
                    state.cpf = cpf;
                }
                if (/^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(oldValue)) {
                    const value = newValue.replace(new RegExp("[.-]", "gi"), "");
                    state.cpf = value;
                }
            }
        );

        watch(
            () => state.companyIdentityDocument,
            (newValue) => {
                localStorage.setItem(`EP_SCHEDULING_CNPJ_${state.selectedUf}`, newValue);
            }
        );

        const changeUf = async (uf: string) => {
            state.selectedUf = uf;
            state.gerarProva = true;
            state.renach = `${state.selectedUf.substring(0, 2)}123456789`;

            if (state.selectedUf == "DF") {
                state.companyIdentityDocument = "00475855000179";
                state.gerarProva = false;
            }
            else if (state.selectedUf == "CE") {
                state.companyIdentityDocument = "07135668000195";
            }
            else if (state.selectedUf == "PE") {
                state.companyIdentityDocument = localStorage.getItem(`EP_SCHEDULING_CNPJ_PE`) ?? "09559896000118";
            }
            else if (state.selectedUf == "MA") {
                state.companyIdentityDocument = localStorage.getItem(`EP_SCHEDULING_CNPJ_MA`) ?? "04747979000117";
            }
            else {
                state.companyIdentityDocument = localStorage.getItem(`EP_SCHEDULING_CNPJ_${state.selectedUf}`) ?? "";
            }
        };

        const generateGestorExam = async (): Promise<CreateExamDTO | null> => {
            state.schedulingId = uuid.v4();
            let examinadoresQAs = ["72205179209","38731176062"];
            let examinadorCpf = "";
            if(state.selectedUf == "PB") {
                examinadorCpf = examinadoresQAs[Math.floor(Math.random() > 0.5 ? 0 : 1)]; 
            }

            try {
                const createExam: CreateExamDTO = {
                    "candidateCpf": state.cpf.replaceAll(".", "").replaceAll("-", ""),
                    "candidateName": state.name,
                    "schedulingDate": nowISO(),
                    "schedulingId": state.schedulingId,
                    "companyCnpj": state.companyIdentityDocument,
                    "type": state.selectedExamType,
                    "renach": state.renach,
                    "schedulingLocal": localStorage.getItem("EP_SCHEDULING_LOCAL") || "1",
                    "schedulingLocalDescription": "Exame Demo",
                    "schedulingTurn": 1,
                    "examinadorCpf": examinadorCpf,
                    "processStartDate": new Date()
                }
                await GestorService.createExamGestor(createExam);

                return createExam;
            } catch (error) {
                console.log("error", error);
            }

            return null;
        }

        const submit = async (data: { isValid: boolean; errors: string[]; }) => {
            state.error = "";
            state.loading = true;

            if (!data.isValid) {
                if (vm.federativeUnitSelected == "") {
                    state.errorUf = true;

                } else {
                    state.errorUf = false;
                }

                state.error = data.errors[0];
            } else {
                if (vm.federativeUnitSelected == "") {
                    state.errorUf = true;
                    throw state.error = "Estado inválido";
                } else {
                    state.errorUf = false;
                }

                const exam = await generateGestorExam();

                if (state.gerarProva && exam) {
                    if (state.selectedUf == "CE") {
                        const generatedExam = await ExameService.GenerateExamQuestion(state.cpf.replaceAll(".", "").replaceAll("-", ""), 6, state.selectedExamType)[0];
                        ExameService.CreateScheduling({
                            "companyCNPJ": state.companyIdentityDocument,
                            "schedulingDate": nowISO(),
                            "schedulingIntegrationId": state.schedulingId,
                            "examType": Number(state.selectedExamType),
                            "candidateCpf": state.cpf.replaceAll(".", "").replaceAll("-", ""),
                            "candidateName": state.name,
                            "viewerCpf": "",
                            "federativeUnit": state.selectedUf,
                            ...generatedExam,
                        });
                    }
                    if (state.selectedUf == "MA") {
                        const generatedExam = await ExameService.GenerateExamQuestion(state.cpf.replaceAll(".", "").replaceAll("-", ""), 10, state.selectedExamType)[0];
                        ExameService.CreateScheduling({
                            "companyCNPJ": state.companyIdentityDocument,
                            "schedulingDate": nowISO(),
                            "schedulingIntegrationId": state.schedulingId,
                            "examType": Number(state.selectedExamType),
                            "candidateCpf": state.cpf.replaceAll(".", "").replaceAll("-", ""),
                            "candidateName": state.name,
                            "viewerCpf": "",
                            "federativeUnit": state.selectedUf,
                            ...generatedExam,
                        });
                    }
                    
                    else await GestorService.unlockExam(exam?.schedulingId);
                }
                replace({ name: "demo-biometry", params: { cpf: state.cpf } });

                /*
                if (vm.federativeUnitSelected == "") {
                    state.errorUf = true;
                    throw state.error = "Estado inválido";
                } else {
                    state.errorUf = false;
                }

                try {
                    state.loading = true;

                    const requestSchedulingDemo = await ExameService.GetTesteExameTeorico()[0];
                    const responseSchedulingDemo = await ExameService.CreateScheduling({
                        ...requestSchedulingDemo,
                        candidateCpf: state.cpf.replaceAll(".", "").replaceAll("-", ""),
                        candidateName: state.name,
                        federativeUnit: vm.federativeUnitSelected
                    })[0];


                    if (responseSchedulingDemo) {
                        replace({ name: "demo-biometry", params: { cpf: state.cpf } });
                        trackers.registerPageView('DemoRegister', `/demo-biometry/${state.cpf}`)
                    }



                } catch (error: any) {
                    trackers.registerError('Erro', error.toString())
                    state.error = error;
                    state.loading = false;
                }
                    */
            }
            state.loading = false;
        };

        return { state, submit, required, validEmailOrCpf, validCpf, changeUf };
    },
});

export default DemoRegisterView;
