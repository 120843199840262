import { formatISO, parse } from "date-fns";
import { uuid } from 'vue-uuid';

export const sentenceCase = (value: string): string => {
    const lowerCase = value.slice(1, value.length)?.toLowerCase();
    const upperCase = value[0]?.toUpperCase();
    if(lowerCase && upperCase) {
        return upperCase + lowerCase;
    }
    return value;
};

export const titleCase = (value: string): string  => {
    return value.split(" ").map(word => {
        return sentenceCase(word);
    }).join(" ");
};

export const maskCpf = (value: string): string => {
    return `${value.substr(0,3)}.${value.substr(3,3)}.${value.substr(6,3)}-${value.substr(9,3)}`;
};

export const removeCpfMask = (value: string): string => {
    return value.replace(new RegExp('[.-]', 'gi'), '');
};

export const toISODate = (value: string) => {
    const date = parse(value, 'dd/MM/yyyy', new Date());
    return formatISO(date, { representation: 'date' });
};

export const deviceId = (): string => {
    let id: string | null = localStorage.getItem("deviceId");
    if (!id) {
        id = uuid.v4();
        localStorage.setItem("deviceId", id);
    }
    return id;
};

export const gerarNomeAleatorio = (): string => {
    const nomes = [
        "Ana", "Beatriz", "Carlos", "Daniel", "Eduarda", 
        "Fernanda", "Gabriel", "Heloísa", "Igor", "João", 
        "Larissa", "Marcelo", "Natália", "Otávio", "Patrícia", 
        "Renato", "Sabrina", "Thiago", "Vanessa", "Yasmin"
    ];

    const sobrenomes = [
        "Silva", "Santos", "Oliveira", "Pereira", "Costa", 
        "Almeida", "Souza", "Ferreira", "Rodrigues", "Martins", 
        "Lima", "Barbosa", "Ribeiro", "Gomes", "Carvalho", 
        "Araújo", "Monteiro", "Mendes", "Nascimento", "Andrade"
    ];

    const nome = nomes[Math.floor(Math.random() * nomes.length)];
    const sobrenome = sobrenomes[Math.floor(Math.random() * sobrenomes.length)];

    return `${nome} ${sobrenome}`;
};

export const gerarCpfAleatorio = (): string => {
    // Função para gerar um número aleatório entre 0 e 9
    const gerarDigitoAleatorio = (): number => Math.floor(Math.random() * 10);

    // Gerar os 9 primeiros dígitos do CPF
    const cpfBase = Array.from({ length: 9 }, gerarDigitoAleatorio);

    // Função para calcular um dígito verificador
    const calcularDigitoVerificador = (base: number[]): number => {
        const soma = base.reduce((acc, num, index) => acc + num * (base.length + 1 - index), 0);
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
    };

    // Calcular os dois dígitos verificadores
    const primeiroDigito = calcularDigitoVerificador(cpfBase);
    const segundoDigito = calcularDigitoVerificador([...cpfBase, primeiroDigito]);

    // Combinar todos os números
    const cpfCompleto = [...cpfBase, primeiroDigito, segundoDigito];

    // Formatar o CPF como xxx.xxx.xxx-xx
    return cpfCompleto.map(String).join('').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};