import { Get, Post } from './BaseService';

import ViewerCheckInLoginDTO from '@/dtos/ViewerCheckInLoginDTO';
import ViewerCheckInDTO from '@/dtos/ViewerCheckInDTO';
import ViewerCheckInCreateRequestDTO from '@/dtos/ViewerCheckInCreateRequestDTO';
import ViewerCheckInResponseDTO from '@/dtos/ViewerCheckInResponseDTO';
import ViewerCheckInFindRequestDTO from '@/dtos/ViewerCheckInFindRequestDTO';
import ViewerCheckInDoCheckOutDTO from '@/dtos/ViewerCheckInDoCheckOutDTO';
import ViewerCheckInDoCheckOutResponseDTO from '@/dtos/ViewerCheckInDoCheckOutResponseDTO';
import ViewerCheckInExamDTO from '@/dtos/ViewerCheckInExamDTO';

const ViewerCheckInService = {
    Login: (data: ViewerCheckInLoginDTO) => {
        return Post<ViewerCheckInLoginDTO, ViewerCheckInDTO>('api/Auth/viewer-checkin', data);
    },
    CheckIn: (data: ViewerCheckInCreateRequestDTO) => {
        return Post<ViewerCheckInCreateRequestDTO, ViewerCheckInResponseDTO>('api/SchedulingLocalCheckIn/checkIn', data);
    }, 
    FindCheckIn: (data: ViewerCheckInFindRequestDTO) => {
        return Get<ViewerCheckInResponseDTO>(
            `api/SchedulingLocalCheckIn/findCheckIn?viewerCpf=${data.viewerCpf}&schedulingLocal=${data.schedulingLocal}`);
    }, 
    CheckOut: (data: ViewerCheckInDoCheckOutDTO) => {
        return Post<ViewerCheckInDoCheckOutDTO, ViewerCheckInDoCheckOutResponseDTO>('api/SchedulingLocalCheckIn/checkOut', data, { timeout: 300000 });
    },
    GetCheckInExams: (checkInId: string) => {
        return Get<ViewerCheckInExamDTO[]>(`api/SchedulingLocalCheckIn/exams?checkInId=${checkInId}`, {}, { timeout: 300000 });
    }
};

export default ViewerCheckInService;

