
import { defineComponent, ref, onMounted, watchEffect } from 'vue';
import Modal from './Modal.vue';
import vm from "@/viewModels/MainViewModel";
import Button from "@/components/Button.vue";
import Loading from "@/components/Loading.vue";
import ViewerCheckInService from '@/services/ViewerCheckInService';
import { useAlert } from '@/hooks/alert';
import ViewerCheckInResponseDTO from '@/dtos/ViewerCheckInResponseDTO';
import UsuarioService from '@/services/UsuarioService';
import { maskCpf } from '@/utils/stringFunctions';

interface Option {
    title: string;
    action?: (args?: any) => void;
    primary?: boolean;
}

interface CheckInResult {
    success: boolean, 
    errorMessage: string | null, 
    payload: ViewerCheckInResponseDTO | null
}

const OpenViewerRoomModal = defineComponent({
    components: { Modal, Button, Loading },
    setup() {
        const actions = ref<Option[]>([]);

        const icon = ref('alert');
        const title = ref('Opss...');
        const message = ref('');
        const isOpened = ref(false);
        const detail = ref('');
        const color = ref('');

        const loading = ref<boolean>(false);
        
        const viewer1Cpf = ref("");
        const viewer1Email = ref("");
        const viewer1Name = ref("");
        const schedulingLocal = ref("");

        const viewer2Cpf = ref('');
        const viewer2Email = ref('');
        const viewer2Name = ref('');
        const viewer2Password = ref('');
        const isViewer2Validated = ref(false);
        const isOkDisabled = ref(false);
        const checkInId = ref("");
        const checkInDate = ref<Date>();
        
        onMounted(() => {
            
        });

        const canOpenRoom = () => {
            return isViewer2Validated.value || (
                viewer2Email.value.trim() == '' && viewer2Password.value.trim() == '') 
        }

        const displayAlert = (options: {
            title?: string;
            message: string;
            actions?: Option[];
            icon?: string;
            detail?: string;
            color?: string;
        }) => {

            actions.value = options.actions || [];
            icon.value = options.icon || 'alert';
            title.value = options.title || "Opss...";
            message.value = options.message;
            detail.value = options.detail || '';
            isOpened.value = true;
            color.value = options.color || 'var(--primary-color)';

            isViewer2Validated.value = false;
            viewer2Name.value = "";
            viewer2Email.value = "";
            viewer2Password.value = "";
        };

        const handleAction = async (option: Option) => {

            if(!option.primary){
                loadAction(option.action);
                return;
            }

            if (!canOpenRoom()) {
                const alert = useAlert();
                alert({
                    title: "Atenção",
                    message: "Limpe o formulário ou faça login com o examinador 2 para abrir a sala",
                    color: "red"
                });
                return;
            }

            var result = await checkIn();
            
            if (!result.success) {
                const alert = useAlert();

                checkInId.value = result.payload?.id ?? "";
                checkInDate.value = result.payload?.checkInDate;

                alert({
                    title: "Erro",
                    message: result.errorMessage!,
                    color: "red",
                });
                return;
            }

            loadAction(option.action, result.payload);
        };

        const loadAction = (action?: (args?: any) => void, checkin: ViewerCheckInResponseDTO | null = null) => {
            isOpened.value = false;
            setTimeout(() => {
                if (action != null) {
                    action(checkin);
                }
            }, 300);
        };

        const clearForm = () => {
            isViewer2Validated.value = false;
            viewer2Name.value = "";
            viewer2Email.value = "";
            viewer2Password.value = "";
        };

        watchEffect(() => {
            isOkDisabled.value = viewer2Email.value.trim() == '' || viewer2Password.value.trim() == '' || isViewer2Validated.value;
        });
        watchEffect(() => {
            viewer1Cpf.value = vm.user?.cpf ?? "";
            viewer1Email.value = vm.user?.email ?? "";
            viewer1Name.value = vm.user?.name ?? "";
            schedulingLocal.value = vm.schedulingLocal;
        });

        const checkIn = async (): Promise<CheckInResult> => {
            loading.value = true;

            try {
                const [request] = ViewerCheckInService.CheckIn({
                    viewerCpf1: viewer1Cpf.value,
                    viewerCpf2: viewer2Cpf.value,
                    schedulingLocal: schedulingLocal.value
                });

                const response = await request;
                return { success: true, errorMessage: null, payload: response };
            } catch (error) {
                return { success: false, errorMessage: error as string, payload: null };
            } finally {
                loading.value = false;
            }
        };


        const fetchData = async () => {
            
            if(viewer2Email.value == viewer1Email.value){
                const alert = useAlert();
                alert({
                    message: "O examinador 2 não pode ser o mesmo que o examinador 1.",
                    color: "red"
                });
                return;
            }
            // useAlert tem que ser chamado aqui para ser renderizado por cima deste modal
            // isso acontece porque a ordem de inclusão dos hooks (que são apps) em main.ts define a ordem de renderização
            // mas como o alert é dependência deste modal, ele não pode ser chamado no setup, porque isso geraria erro de dependência não resolvida
            // e por que foi feito assim? porque não temos tempo de resolver isso agora
            const alert = useAlert();
            loading.value = true;

            const [request] = UsuarioService.Login({
                email: viewer2Email.value,
                password: viewer2Password.value
            });

            request.then((response) => {

                // Validação a uf do usuário
                if(response.federativeUnit != vm.user?.federativeUnit){
                    alert({
                        message: "Usuário não encontrado!",
                        color: "red"
                    });
                    return;
                }

                // Validação a permissão do usuário
                if(response.roleDescription != "VIEWER" && response.roleDescription != "ROOM_INSPECTOR"){
                    alert({
                        message: "Usuário não tem permissão para participar da sala!",
                        color: "red"
                    });
                    return;
                }

                viewer2Name.value = response.name;
                isViewer2Validated.value = true;
                viewer2Email.value = response.email;
                viewer2Cpf.value = response.cpf;
                viewer2Name.value = response.name;

                alert({
                    title: "OK",
                    message: "Examinador associado com sucesso!",
                    icon: "check",
                    color: "green",
                });
                
            })
                .catch((error) => {
                    alert({
                        title: "Erro",
                        message: error,
                        color: "red"
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        return {
            icon,
            title,
            message,
            isOpened,
            detail,
            loadAction,
            displayAlert,
            actions,
            color,
            viewer1Email, 
            viewer1Cpf, 
            viewer1Name, 
            viewer2Name, 
            viewer2Password, 
            viewer2Cpf, 
            viewer2Email, 
            fetchData, 
            loading, 
            isViewer2Validated,
            handleAction, 
            clearForm, 
            isOkDisabled,
            maskCpf
        };
    }
});

export default OpenViewerRoomModal;
