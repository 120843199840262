
import Button from "@/components/Button.vue";
import Loading from "@/components/Loading.vue";
import Switch from "@/components/ToogleSwitch.vue";
import GestorExamDTO from "@/dtos/gestor/GestorExamDTO";
import { useAlert } from '@/hooks/alert';
import ExameService from "@/services/ExameService";
import { maskCpf, removeCpfMask } from '@/utils/stringFunctions';
import vm from "@/viewModels/MainViewModel";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import FinishedExamCard from "./FinishedExamCard.vue";

const alert = useAlert();

const FinishedExamView = defineComponent({
    components: { Switch, FinishedExamCard, Button, Loading },
    setup() {

        const exams = ref<GestorExamDTO[]>([]);
        const searchText = ref<string>('');
        const showAllExamsChecked = ref<boolean>(false);
        const loading = ref<boolean>(false);
        const printingCertificate = ref<boolean>(false);
        const printingExam = ref<GestorExamDTO>();
        const saving = ref<boolean>(false);
        const statsStarted = ref<number>(0);
        const statsDone = ref<number>(0);
        const statsTotal = ref<number>(0);

        let timerGetExams: number;

        const fetchData = async () => {
            loading.value = true;
            try {
                await Promise.allSettled([getExams(), getStatusStats()]);
            }
            catch(error){
                alert({ message: "Erro ao carregar os exames" });
            }
            finally {
                loading.value = false;
            }
        };

        const getExams = async () => {
            exams.value = await ExameService.GetFinishedExamsToday(vm.schedulingLocal)[0];
        };

        const getStatusStats = async () => {
            const stats = await ExameService.GetGestorStatusStats(vm.schedulingLocal, (new Date()).toISOString())[0];
            statsStarted.value = stats.started;
            statsDone.value = stats.done;
            statsTotal.value = stats.total;
        };

        const printCertificate = async (exam: GestorExamDTO) => {
            printingExam.value = exam;
            printingCertificate.value = true;
            localStorage.setItem("certificate", JSON.stringify(exam));
            window.open('/certificate', 'iframePrint');
        };

        const certificateGivenAtChange = async (exam: GestorExamDTO, certificateGivenAt: string) => {
            saving.value = true;
            try {
                await ExameService.UpdateCertificateGivenAt(exam.id, certificateGivenAt)[0];
                exam.certificateGivenAt = certificateGivenAt;
            }
            catch(_error){
                alert({ message: "Erro ao atualizar essa informação no banco de dados. Tente novamente mais tarde" });
            }
            finally {
                saving.value = false;
            }
        };

        const clearSearchText = async () => {
            searchText.value = '';
        };

        const confirm = (exam: GestorExamDTO) => {
            alert({
                title: "Confirmação",
                message: "O certificado foi entregue para o candidato ?",
                actions: [
                    { title: "Não" },
                    {
                        title: "Sim",
                        primary: true,
                        action: async () => {
                            if (exam) {
                                await certificateGivenAtChange(exam, new Date().toISOString());
                                await fetchData();
                            }
                        }
                    }
                ]
            });
        };

        onMounted(async () => {

            window.addEventListener('message', function(event) {
                const message = event.data;
                if (message.type === 'onLoad') {
                    printingCertificate.value = false;
                    if (printingExam.value && !printingExam.value.certificateGivenAt)
                        confirm(printingExam.value);
                }
            });

            await fetchData();

            try{
                clearInterval(timerGetExams);
            }
            catch(_error) { 
                console.log("Error clearing interval");
            }

            timerGetExams = setInterval(async () => {
                await Promise.allSettled([getExams(), getStatusStats()]);
            }, 60000);
        });

        onUnmounted(async () => {
            clearInterval(timerGetExams);
        });

        const filteredExams = computed<GestorExamDTO[]>((): GestorExamDTO[] => {
            return exams.value.filter(exam => {
                if (showAllExamsChecked.value) {
                    return exam.candidate.name.toLowerCase().includes(searchText.value.toLowerCase()) || removeCpfMask(exam.candidate.identityDocument).includes(removeCpfMask(searchText.value));
                }
                else {
                    return (exam.candidate.name.toLowerCase().includes(searchText.value.toLowerCase()) || removeCpfMask(exam.candidate.identityDocument).includes(removeCpfMask(searchText.value))) && exam.certificateGivenAt == null;
                }
            });
        });

        return {
            vm,
            filteredExams, 
            exams,
            maskCpf, 
            searchText, 
            clearSearchText, 
            showAllExamsChecked, 
            printCertificate, 
            certificateGivenAtChange, 
            getExams,
            loading,
            fetchData,
            printingCertificate,
            printingExam,
            confirm,
            saving,
            statsStarted,
            statsDone,
            statsTotal
        };
    },

});

export default FinishedExamView;
