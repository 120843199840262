import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded-md shadow-md hover:bg-gray-gray-100 p-2 border-1 border flex flex-row items-start hover:bg-yellow-100" }
const _hoisted_2 = { class: "pl-4 w-full" }
const _hoisted_3 = { class: "flex flex-row items-start" }
const _hoisted_4 = { class: "text-lg font-semibold" }
const _hoisted_5 = { class: "text-lg text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", null, [
      _createVNode("div", {
        class: "image-container w-36 h-30 rounded-md bg-cover bg-center",
        style: `background-image: url('data:image/jpeg;base64,${_ctx.exam.faceUrl}');`
      }, null, 4)
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, _toDisplayString(_ctx.maskCpf(_ctx.exam.cpf)), 1)
      ]),
      _createVNode("div", _hoisted_5, _toDisplayString(_ctx.exam.name), 1)
    ])
  ]))
}